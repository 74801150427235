import { FormControl, FormGroup, Validators } from '@angular/forms';
import { nameValidators } from '@core/constants/forms/form-validators.constants';
import {
  CreateParentFormConfig,
  ParentFormModel,
  ParentFormPayload,
  ParentFormValue,
} from '../models/parent-form.models';
import { FormValidators } from '@core/models/form-errors.models';
import { Parent } from '@core/models/entities/parent.models';
import { WlcmAutocompleteOption, WlcmOption } from '@wlcm/angular/core';
import { FamilyMemberFormMode } from '../models/family-member-form.models';

export const createParentForm = (config: CreateParentFormConfig = { mode: 'write' }): FormGroup<ParentFormModel> => {
  const form: FormGroup<ParentFormModel> = new FormGroup<ParentFormModel>({
    id: new FormControl(null),
    full_name: new FormControl('', [Validators.required, ...nameValidators]),
    email: new FormControl('', [Validators.required, FormValidators.email]),
    phone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    notes: new FormControl('', [Validators.minLength(5), Validators.maxLength(100_000)]),
    photo_release: new FormControl(false),
  });

  if (config?.mode === 'select') {
    form.controls.full_name.setValidators([Validators.required]);
    form.controls.email.disable();
    form.controls.phone.disable();
    form.controls.address.disable();
  }

  return form;
};

export const patchSelectableParentForm = (
  form: FormGroup<ParentFormModel>,
  parent: Parent,
  patchFullName: boolean = true
): void => {
  const formValue: ParentFormValue = transformToParentFormValue(parent, 'select');

  if (patchFullName) {
    form.controls.full_name.setValue(formValue.full_name);
  }

  form.controls.address.setValue(formValue.address);
  form.controls.email.setValue(formValue.email);
  form.controls.phone.setValue(formValue.phone);
};

export const transformToParentFormValue = (parent: Parent, mode: FamilyMemberFormMode = 'write'): ParentFormValue => {
  const payload: Partial<ParentFormValue> = {
    id: parent._id,
    email: parent.email,
    phone: parent.phone,
    notes: parent.notes,
    photo_release: parent.photo_release,
  };

  if (mode === 'select') {
    payload.full_name = new WlcmOption(parent._id, parent.full_name, parent);
  } else {
    payload.full_name = parent.full_name;
  }

  if (parent.address) {
    const addressPrediction: Partial<google.maps.places.AutocompletePrediction> = {
      place_id: parent.address.place_id,
      description: parent.address.formatted_address,
    };

    payload.address = new WlcmAutocompleteOption(
      parent.address.place_id,
      parent.address.formatted_address,
      parent.address.formatted_address,
      addressPrediction
    );
  }

  return payload as ParentFormValue;
};

export const transformToParentStore = (formValue: ParentFormValue): ParentFormValue => {
  const payload: Partial<ParentFormValue> = {
    id: formValue.id,
    email: formValue.email,
    phone: formValue.phone,
    full_name: formValue.full_name,
  };

  if (formValue.address) {
    const addressPrediction = <google.maps.places.AutocompletePrediction>formValue.address?.data;
    payload.address = {
      ...formValue.address,
      data: { place_id: addressPrediction.place_id, description: addressPrediction.description },
    };
  }

  return payload as ParentFormValue;
};

export const transformToParentPayload = (formValue: ParentFormValue): ParentFormPayload => {
  const payload: Partial<ParentFormPayload> = {
    id: formValue.id,
    email: formValue.email,
    phone: formValue.phone,
    notes: formValue.notes,
    photo_release: formValue.photo_release,
  };

  if (formValue.full_name instanceof Object) {
    payload.full_name = formValue.full_name.value as string;
  } else {
    payload.full_name = formValue.full_name;
  }

  const addressPrediction: google.maps.places.AutocompletePrediction = <google.maps.places.AutocompletePrediction>(
    formValue.address?.data
  );

  payload.address = { place_id: addressPrediction?.place_id, formatted_address: addressPrediction?.description };

  return payload as ParentFormPayload;
};
